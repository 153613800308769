import * as braze from '@braze/web-sdk';
import * as Sentry from '@sentry/vue';

const initializeBraze = (sdkApiKey, sdkUrl) =>
  new Promise(resolve => {
    resolve(braze.initialize(sdkApiKey, { baseUrl: sdkUrl }));
  });

const brazeModule = {
  async init() {
    try {
      const BRAZE_SETTINGS = window.KOG?.DJANGO_SETTINGS?.BRAZE;
      const USER_CONSTANTS = window.KOG?.USER_CONSTANTS;

      const sdkApiKey = BRAZE_SETTINGS?.BRAZE_SDK_API_KEY;
      const sdkUrl = BRAZE_SETTINGS?.BRAZE_SDK_URL;
      const userId = USER_CONSTANTS?.User.id;

      const isStudent = USER_CONSTANTS?.User.roles.includes('Student');
      if (isStudent) {
        return;
      }

      const isInitialized = await initializeBraze(sdkApiKey, sdkUrl);
      if (isInitialized) {
        braze.automaticallyShowInAppMessages();
        braze.changeUser(userId);
        braze.openSession();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  },
  showContentCards(selector, placement = null) {
    let cardCounter = 0;

    const filterCards = cards => {
      let filteredCards;
      if (placement) {
        filteredCards = cards.filter(card => card.extras?.placement === placement);
      } else {
        filteredCards = cards;
      }
      cardCounter = filteredCards.length;
      return filteredCards;
    };

    braze.showContentCards(document.querySelector(selector), filterCards);
    return cardCounter;
  },
};

export default brazeModule;
