<template>
  <div class="flexContainer flexContainer-column">
    <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
    <span
      :class="radioClasses"
      :tabindex="tabIndex"
      class="KogRadio"
      role="radio"
      :aria-checked="isChecked"
      :aria-label="radioLabel"
      :aria-disabled="isDisabled ? 'true' : null"
      :aria-describedby="describedBy"
      @click.stop="select()"
      @keydown.space.prevent="select()"
      @keydown.enter.prevent="select()"
    >
      <span
        v-if="label && !isLabelHidden && isLabelOnLeft"
        class="KogRadio-label"
        aria-hidden="true"
      >
        {{ label }}
      </span>
      <span class="KogRadio-iconOuterContainer">
        <span class="KogRadio-iconInnerContainer" />
      </span>
      <span
        v-if="label && !isLabelHidden && !isLabelOnLeft"
        class="KogRadio-label"
        aria-hidden="true"
      >
        {{ label }}
      </span>
    </span>
    <template v-if="option.customEditor">
      <flat-pickr
        v-if="option.customEditor === 'date'"
        :value="option.customEditorValue"
        :config="option.customEditorConfig"
        :placeholder="option.customEditorConfig.placeholder"
        class="KogRadio-customEditor KogRadio-dateEditor KogFormInput block margin-top-xxs"
        @on-close="onDateSelectionChange"
      />
    </template>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'KogRadio',
  components: {
    flatPickr,
  },
  props: {
    /**
     * The value of the current radio button
     */
    value: {
      type: String,
      required: true,
    },
    /**
     * Whether the radio is checked
     */
    isChecked: {
      type: Boolean,
      default: false,
    },
    /**
     * If disabled, the radio button is not clickable or focusable
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Set this if you want a custom element to describe this option for screen readers
     */
    describedBy: {
      type: String,
      default: '',
    },
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use isLabelHidden: true
     */
    label: {
      type: String,
      default: '',
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Set this to true if you want the label to appear on the left side of the radio.
     */
    isLabelOnLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * The checked radio should always have `tabindex=0`.
     * If there is no checked radio in the group, the first radio should have `tabindex=0`.
     * All the others have `tabindex=-1`
     */
    tabIndex: {
      type: Number,
      default: null,
    },
    option: {
      type: Object,
      required: true,
      validator: prop => {
        if (
          'customEditor' in prop &&
          (![null, 'date'].includes(prop.customEditor) || !('customEditorValue' in prop))
        ) {
          return false;
        }

        return true;
      },
    },
  },
  emits: ['checked', 'custom-option-value-change'],
  computed: {
    radioClasses() {
      const cssClasses = [];
      if (this.isLabelOnLeft) {
        cssClasses.push('KogRadio-leftLabel');
      }

      if (this.isDisabled) {
        cssClasses.push('KogRadio-disabled');
      } else {
        cssClasses.push('KogRadio-enabled');
      }

      if (this.isChecked) {
        cssClasses.push('is-checked');
      } else {
        cssClasses.push('is-unchecked');
      }

      return cssClasses;
    },
    radioLabel() {
      if (this.option.customEditorValue) {
        return `${this.label}: ${this.option.customEditorValue}`;
      }

      return this.label;
    },
  },
  methods: {
    select() {
      if (this.isDisabled) {
        return;
      }

      if (this.option.customEditor && !this.option.customEditorValue) {
        return;
      }

      const newValue = this.option.customEditor ? this.option.customEditorValue : this.value;
      /**
       * Emits new value after being checked
       *
       * @event checked
       * @type {string}
       */
      this.$emit('checked', newValue);
    },
    onDateSelectionChange(_, dateStr) {
      /**
       * Emits new value when the custom editor value changes
       *
       * @event custom-option-value-change
       * @type {string}
       */
      this.$emit('custom-option-value-change', { ...this.option, newValue: dateStr });

      if (dateStr.length > 0) {
        this.$emit('checked', dateStr);
      }
    },
  },
};
</script>

<style scoped>
.KogRadio {
  overflow-y: visible;
  display: flex;
  align-items: center;

  width: fit-content;
  min-height: 24px;
  max-height: 24px;
}

.KogRadio:focus {
  border-radius: 1000px;
}

.KogRadio-label {
  padding-right: var(--space-xxs);
  padding-left: var(--space-xxs);
  line-height: 24px;
  color: var(--kog-radio-label);
}

.KogRadio-leftLabel {
  text-align: right;
}

.KogRadio-iconOuterContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-l);
  height: var(--space-l);

  border-radius: 50%;
}

.KogRadio-iconInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;

  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
}

.KogRadio-iconInnerContainer::after {
  content: '';

  display: block;

  border-color: var(--kogInherit);
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
}

.KogRadio-customEditor {
  width: auto;
  margin-left: var(--space-xl);
}

.KogRadio-dateEditor {
  min-width: 210px;
}

.KogRadio-enabled .KogRadio-iconOuterContainer,
.KogRadio-enabled .KogRadio-label {
  cursor: pointer;
}

.KogRadio.is-unchecked .KogRadio-iconInnerContainer::after {
  border-color: var(--kogTransparent);
}

.KogRadio-enabled .KogRadio-iconInnerContainer {
  color: var(--kog-radio-color);
  border-color: var(--kog-radio-border);
}

.KogRadio-disabled .KogRadio-iconInnerContainer {
  color: var(--kog-radio-color-disabled);
  border-color: var(--kog-radio-border-disabled);
}

.KogRadio-disabled .KogRadio-label {
  color: var(--kog-radio-label-disabled);
}

.KogRadio-enabled .KogRadio-iconOuterContainer:hover {
  background-color: var(--kog-radio-hover);
}

.KogRadio.is-unchecked:not(.KogRadio-disabled) .KogRadio-iconInnerContainer {
  border-color: var(--kog-radio-border-unckecked);
}
</style>
