<template>
  <div
    role="radiogroup"
    :aria-labelledby="getUniqueId('label')"
  >
    <span
      :id="getUniqueId('label')"
      class="screenreader-only"
    >
      {{ groupLabel }}
    </span>
    <kog-tag
      v-for="option in options"
      :key="option.value"
      is-chip
      class="margin-right-xxs"
      role="radio"
      :label="option.label"
      :type="option.value === selectedValue ? selectedTagType : unselectedTagType"
      :aria-checked="option.value === selectedValue"
      :size="size"
      :disabled="disabled"
      @click="select(option)"
      @keydown.space.prevent="select(option)"
    />
  </div>
</template>
<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';

export default {
  name: 'TagRadioGroup',
  components: {
    KogTag,
  },
  props: {
    /**
     * A text that explains what the group is for
     */
    groupLabel: {
      type: String,
      default: '',
    },
    /**
     * The value that is currently selected within the radio-group.
     * It can be used as the write-only alternative to v-model
     */
    selectedValue: {
      type: String,
      required: true,
    },
    /**
     * The list of options to be rendered
     */
    options: {
      type: Array,
      required: true,
    },
    unselectedTagType: {
      type: String,
      default: 'default',
      validator: value =>
        [
          'default',
          'basic',
          'outline',
          'outlineInformative',
          'informative',
          'informative2',
          'informative3',
          'warning',
          'outlineWarning',
          'positive',
          'negative',
          'accent',
        ].includes(value),
    },
    selectedTagType: {
      type: String,
      default: 'informative',
      validator: value =>
        [
          'default',
          'basic',
          'outline',
          'outlineInformative',
          'informative',
          'informative2',
          'informative3',
          'warning',
          'outlineWarning',
          'positive',
          'negative',
          'accent',
        ].includes(value),
    },
    size: {
      type: String,
      default: 'm',
      validator: value => ['m', 'l'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['checked', 'update:selectedValue'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  methods: {
    select(option) {
      this.$emit('checked', option.value);
      this.$emit('update:selectedValue', option.value);
    },
  },
};
</script>
