import { createNewBattle, fetchBattle, fetchSetupInfo } from 'studyApp/api/battle.js';

const mutations = {
  setSelectedNode(state, selectedNode) {
    state.selectedNode = selectedNode;
  },
  setSetupInfo(
    state,
    { ongoing_battles: ongoingBattles, past_battles: pastBattles, classmates, stats },
  ) {
    state.ongoingBattles = ongoingBattles;
    state.pastBattles = pastBattles;
    state.classmates = classmates;
    state.stats = stats;
  },
  setCurrentBattle(state, battle) {
    state.currentBattle = battle;
  },
  clearSelectedNode(state) {
    state.selectedNode = null;
  },
};

const actions = {
  async fetchSetupInfo({ commit }, { subjectId, subjectClassId }) {
    commit('setSetupInfo', await fetchSetupInfo({ subjectId, subjectClassId }));
  },
  async createNewBattle({ commit }, { subjectId, subjectNodeId, opponent }) {
    commit('setCurrentBattle', await createNewBattle(subjectId, subjectNodeId, opponent));
  },
  async fetchBattle({ commit }, battleId) {
    commit('setCurrentBattle', await fetchBattle(battleId));
  },
};

const state = {
  selectedNode: null,
  ongoingBattles: null,
  pastBattles: null,
  classmates: null,
  stats: null,
  currentBattle: null,
};

const getters = {
  battlesWon(currentState) {
    return currentState.stats?.won || 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
