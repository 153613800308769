export const assignmentErrorText = {
  save_assignment: {
    assignmentAlreadySubmitted: 'Failed to save, assignment already submitted.',
    questionAlreadySubmitted: 'Failed to save, answer already submitted.',
    unknown:
      'Saving attempt failed. All attempts to save prior to this point have been successful.',
  },
  submit_answer: {
    assignmentAlreadySubmitted: 'Failed to submit, assignment already submitted.',
    questionAlreadySubmitted: 'Failed to submit, answer already submitted.',
    deadlinePassed:
      'The deadline for this assignment has passed. You can no longer submit answers.',
    unknown: 'Failed to submit answer. Reload the page and try again.',
  },
  submit_assignment: {
    assignmentAlreadySubmitted: 'Failed to submit the assignment, assignment already submitted.',
    unknown: 'Failed to submit the assignment. Reload the page and try again.',
  },
};
