<script setup lang="ts">
import assessmenAndHomeworkTools from 'sharedApp/img/activation/assessment-and-homework-tools.png';
import interactiveTextbook from 'sharedApp/img/activation/interactive-textbook.png';
import progressAnalytics from 'sharedApp/img/activation/progress-analytics.png';
import arrowVector from 'sharedApp/img/activation/vector-arrow.svg';

const images = [
  {
    label: 'Interactive textbook',
    src: interactiveTextbook,
  },
  {
    label: 'Assessment and homework tools',
    src: assessmenAndHomeworkTools,
  },
  {
    label: 'Progress analytics',
    src: progressAnalytics,
  },
];
</script>

<template>
  <div class="flexContainer flexContainer-column flexContainer-center margin-top-xxxl">
    <div class="flexContainer">
      <p class="heading-m margin-bottom-m margin-top-m">Interactive learning platform</p>
      <div class="InteractiveLearningPlatform-ImageContainer">
        <img
          :src="arrowVector"
          alt=""
          class="InteractiveLearningPlatform-imageContainer-image"
        />
      </div>
    </div>
    <p class="heading-xxs">Curriculum-aligned course resources. All in one place.</p>

    <div
      class="InteractiveLearningPlatform__image_row flexContainer margin-top-xxxl width-full"
      style="justify-content: space-evenly"
    >
      <div
        v-for="image in images"
        :key="image.label"
        class="InteractiveLearningPlatform-image flexContainer flexContainer-column flexContainer-center padd-m border-radius-8"
      >
        <img
          :src="image.src"
          alt=""
        />
        <p class="text-small font-weight-bold text-center">{{ image.label }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.InteractiveLearningPlatform-image {
  width: 25%;
  background-color: var(--kogPlatformWhite);
}

.InteractiveLearningPlatform-ImageContainer {
  position: relative;
  width: 0;
  height: 0;
}

.InteractiveLearningPlatform-imageContainer-image {
  position: absolute;
  top: -52px;
  left: 15px;
}
</style>
