import * as Sentry from '@sentry/vue';
import posthog, { PostHog } from 'posthog-js';

import type { IsFeatureEnabledOptions, PostHogConfig } from 'posthog-js';

import settings from 'sharedApp/django-settings-module.ts';

const EIGHT_WEEKS_MS = 8 * 7 * 24 * 60 * 60 * 1000;

export type PartialPostHogConfig = Partial<PostHogConfig>;
export type UserProperties = {
  role?: 'teacher' | 'student';
  is_demo?: boolean;
  is_internal_user?: boolean;
  'Google classroom teacher'?: boolean;
  created_at?: Date;
  older_than_8w?: boolean;
};
export type EventProperties = Record<string, unknown>;

let posthogInstance: PostHog | null = null;

const apiKey = settings.POSTHOG?.API_KEY;

export const FEATURE_FLAGS = {
  assignmentFlowRecordingEnabled: 'journey_assignment_creation_recording',
};

export default {
  init(userId: number, userProperties?: UserProperties, posthogConfig?: PartialPostHogConfig) {
    const properties = userProperties || {};

    if (!userId) {
      Sentry.captureMessage('PostHog: userId is required');
      return;
    }

    if (properties.role === 'student') {
      return;
    }

    const { created_at: createdAt } = properties;
    if (createdAt) {
      properties.older_than_8w = createdAt < new Date(Date.now() - EIGHT_WEEKS_MS);
    }

    if (apiKey) {
      posthogInstance = posthog.init(apiKey, {
        api_host: 'https://us.posthog.com',
        ...posthogConfig,
      }) as PostHog;
      posthogInstance.identify(userId.toString(), properties);
    }
  },

  isInternalUser(email: string, schoolName: string) {
    if (email.includes('kognity.com')) {
      return true;
    }
    if (['kognity', 'sandbox'].some(keyword => schoolName.toLowerCase().includes(keyword))) {
      return true;
    }
    return false;
  },

  setUserProperties(userProperties: UserProperties) {
    posthogInstance?.setPersonProperties(userProperties);
  },

  setDefaultEventProperties(defaultEventProperties: EventProperties) {
    posthogInstance?.register(defaultEventProperties);
  },

  capture(eventName: string, properties?: EventProperties) {
    posthogInstance?.capture(eventName, properties);
  },

  startSessionRecording() {
    posthogInstance?.startSessionRecording();
  },

  isFeatureEnabled(featureName: string, options?: IsFeatureEnabledOptions): boolean {
    return posthogInstance?.isFeatureEnabled(featureName, options) || false;
  },
};
