import flags from 'sharedApp/const/feature-flags.ts';
import { featureFlags } from 'sharedApp/services/featureflags/featureflags.ts';

export default {
  install(app) {
    featureFlags().init();
    let contextPromiseResolve;
    const contextPromise = new Promise(resolve => {
      contextPromiseResolve = resolve;
    });

    const featureFlagsMethods = {
      pluginContext: {
        userId: null,
        schoolId: null,
      },
      registerContext({ userId, schoolId }) {
        this.pluginContext.userId = userId;
        this.pluginContext.schoolId = schoolId;

        contextPromiseResolve();
      },
      isFeatureEnabled(flag, context = {}) {
        return contextPromise.then(() =>
          featureFlags().isFeatureEnabled(flag, {
            user: {
              id: this.pluginContext.userId,
              school: {
                id: this.pluginContext.schoolId,
              },
            },
            ...context,
          }),
        );
      },
      showActivitySheetAnswerKeys(subjectId) {
        return this.isFeatureEnabled(flags.SHOW_ACTIVITY_SHEET_ANSWER_KEY, {
          subject_id: `${subjectId}`,
        });
      },
    };

    /* Plugin Composition API */
    app.provide('featureFlags', featureFlagsMethods);

    /* Plugin Options API */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$featureFlags = featureFlagsMethods;
  },
};
